import React, { useMemo, useRef, useState } from "react"
import styled from "styled-components"
import { getFromTheme as theme, styledMap } from "../../../utils/styles"
import { H3, H5, P } from "../../common/typography"
import Marker from "../../markers"
import HighlightedText from "../../common/highlightedText"
import { rgba } from "polished"
import {
  ITrackedNodeArg,
  ScrollTrackerState,
  useScrollListener,
  useScrollTracker,
} from "../../../utils/scrollTracker"
import { AnyIcon } from "../../icons/base"
import useBreakpoints from "../../../utils/breakpoints"
import { ThemeColor } from "../../../utils/colors"
import { Screenshot } from "../../images/screenshots"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Component } from "../../types"

const ValuePropsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-items: center;
  gap: 64px;

  @media (max-width: ${theme("breakpoints.large")}px) {
    flex-direction: column;
    gap: 32px;
  }
`

const ScreenshotFader = styled.div`
  opacity: 0.001;
  transition: opacity 0.1s;

  &.selected {
    opacity: 1;
  }
`

const ScreenshotWrapper = styled.div`
  max-width: 24vw;
  width: 24vw;
  top: 0px;
  align-self: flex-start;
  will-change: top;
  transform: top 0.2s;

  @media (max-width: ${theme("breakpoints.large")}px) {
    display: none;
  }
  @media (min-width: ${theme("breakpoints.large")}px) {
    position: relative;

    &.floating {
      position: fixed;
    }

    ${ScreenshotFader} {
      &:not(:first-child) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
`

const ValuePropCaptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
`

const ValuePropSnapshot = styled.div`
  margin-bottom: 42px;
  padding: 0 32px;
  max-width: 420px;
`

const ValuePropStatement = styled.div<{
  selected?: boolean
  color: ThemeColor
}>`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  ${P}, ${H5} {
    text-align: center;
  }

  @media (min-width: ${theme("breakpoints.large")}px) {
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    border-radius: ${theme("borderRadius.cards")}px;
    transition: background-color 0.1s;
    background-color: ${(props) =>
      rgba(theme(`colors.web.${props.color}.xpale`)(props), 0)};

    &.selected {
      background-color: ${(props) =>
        theme(`colors.web.${props.color}.xpale`)(props)};
    }

    &:hover:not(.selected) {
      background-color: ${(props) =>
        rgba(theme(`colors.web.${props.color}.xpale`)(props), 0.4)};
    }

    cursor: pointer;

    ${P}, ${H5} {
      text-align: left;
    }
  }

  ${P} {
    @media (min-width: ${theme("breakpoints.large")}px) {
      max-width: 20vw;
    }
  }
`

const Description = styled(P)``

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme("breakpoints.large")}px) {
    padding-top: 48px;
    max-width: 33vw;
    margin-bottom: calc(30vw + 48px);

    ${ValuePropSnapshot} {
      display: none;
    }

    ${ValuePropStatement} {
      margin-bottom: 42px;
    }

    ${H3} {
      margin-bottom: 32px;
    }

    ${Description} {
      margin-bottom: 48px;
    }
  }

  @media (max-width: ${theme("breakpoints.large")}px) {
    ${ValuePropStatement} {
      max-width: 420px;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${theme("breakpoints.medium")}px) {
    gap: 32px;
  }
  @media (min-width: ${theme("breakpoints.large")}px) {
    gap: 72px;
  }
`

interface IFeatureItem {
  key: string
  icon: AnyIcon
  Screenshot: Screenshot
}

export type ITrackedFeaturedItem = IFeatureItem &
  ITrackedNodeArg<HTMLDivElement>

const FeaturesBlock = ({
  features,
  color,
  titleKey,
}: {
  features: ITrackedFeaturedItem[]
  color: ThemeColor
  titleKey: string
}) => {
  const stickyRef = useRef<HTMLDivElement>(null)
  const { locale } = useIntl()
  const [selectedItem, setSelectedItem] = useState(features[0].key)
  const { breakpoints } = useBreakpoints()

  const [yOffset, setYOffset] = useState(0)
  const [xOffset, setxOffset] = useState(0)
  const [isFloating, setIsFloating] = useState(false)

  const featureKeys = useMemo(() => features.map((f) => f.key), [features])

  const shouldDisplay = (key) =>
    featureKeys.indexOf(key) <= featureKeys.indexOf(selectedItem)

  const tracker = useScrollTracker<HTMLDivElement, HTMLDivElement>({
    trackedNodes: features.map((feature) => ({ ...feature, topOffsetPx: 160 })),
    referenceTopOffsetPx: 100,
    callback: () => {
      if (tracker.state == ScrollTrackerState.within) {
        setYOffset(100)
        setIsFloating(true)
      } else if (tracker.state == ScrollTrackerState.before) {
        setIsFloating(false)
        setYOffset(0)
      } else {
        setIsFloating(false)
        setYOffset(
          tracker.container.current!.offsetHeight -
            stickyRef.current!.offsetHeight
        )
      }
      setSelectedItem(tracker.activeNode ?? features[0].key)
    },
  })

  useScrollListener(() => {
    setxOffset(tracker.container.current!.offsetLeft)
    return tracker.didScroll({
      referenceBottomOffsetPx: stickyRef.current!.offsetHeight,
    })
  })

  return (
    <Wrapper>
      <H3 fluid>
        <HighlightedText color={color} textId={titleKey} />
      </H3>

      <ValuePropsWrapper ref={tracker.container}>
        <div style={{ width: "24vw" }}>
          <ScreenshotWrapper
            style={{
              top: yOffset,
              left: isFloating ? xOffset : "unset",
            }}
            className={isFloating ? "floating" : ""}
            ref={stickyRef}
          >
            {features.map((feature) => {
              const ScreenshotComponent: Component = feature.Screenshot[locale]
              return (
                <ScreenshotFader
                  key={feature.key}
                  className={shouldDisplay(feature.key) ? "selected" : ""}
                >
                  <ScreenshotComponent />
                </ScreenshotFader>
              )
            })}
          </ScreenshotWrapper>
        </div>

        <Content>
          {features.map((feature) => {
            const ScreenshotComponent: Component = feature.Screenshot[locale]
            return (
              <>
                <ValuePropStatement
                  color={color}
                  ref={tracker.refForItem(feature.key)}
                  className={feature.key == selectedItem ? "selected" : ""}
                  key={`${feature.key}-statement`}
                  onClick={() => {
                    if (
                      typeof window !== "undefined" &&
                      window.innerWidth > breakpoints.large
                    ) {
                      return tracker.scrollToNode(feature.key)
                    }
                  }}
                >
                  <Marker color={color} small Icon={feature.icon} />
                  <ValuePropCaptions>
                    <H5>
                      <FormattedMessage id={feature.key + ".title"} />
                    </H5>
                    <P>
                      <FormattedMessage id={feature.key + ".description"} />
                    </P>
                  </ValuePropCaptions>
                </ValuePropStatement>
                <ValuePropSnapshot key={`${feature.key}-screenshot`}>
                  <ScreenshotComponent />
                </ValuePropSnapshot>
              </>
            )
          })}
        </Content>
      </ValuePropsWrapper>
    </Wrapper>
  )
}

export default FeaturesBlock
