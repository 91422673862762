import React, { useEffect } from "react"
import { IntlProvider, navigate, useIntl } from "gatsby-plugin-intl"

import Layout from "../components/site/layout"
import Seo from "../components/common/seo"
import Illustrations from "../components/illustrations"
import Photos from "../components/images/photos"
import SignUp from "../components/blocks/signUp"
import { PageContent, Section } from "../components/common/layout"
import { getLocale, getPathName, messagesForLanguage } from "../utils/localized"
import SolutionSummary from "../components/blocks/solutionSummary"
import PresenterHero from "../components/heroes/presenter"
import FeaturesBlock, {
  ITrackedFeaturedItem,
} from "../components/pages/solutions/features"
import Icons from "../components/icons"
import Screenshots from "../components/images/screenshots"
import Button from "../components/buttons"
import LaunchBlock from "../components/blocks/launch"
import useBreakpoints from "../utils/breakpoints"

const ProfessionalsContent = () => {
  const intl = useIntl()
  const { breakpoints, currentBreakpoint } = useBreakpoints()
  let transform = "none"

  if (currentBreakpoint == "min") {
    transform = "scale(0.7)"
  } else if (currentBreakpoint == "tiny" || currentBreakpoint == "medium") {
    transform = "scale(0.8)"
  } else if (currentBreakpoint == "xsmall") {
    transform = "scale(1)"
  } else {
    transform = "scale(1.1) translateY(-20px)"
  }

  return (
    <PageContent>
      <Section t-xlg h-centered>
        <LaunchBlock />
      </Section>

      <Section t-xlg>
        <FeaturesBlock
          titleKey="solutions.organizations.showcase.title"
          color="secondary"
          features={features}
        />
      </Section>

      <Section t-xlg h-centered h-m-sm>
        <SignUp />
      </Section>
    </PageContent>
  )
}

const ProfessionalsPage = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("user-category", "professionals")
    }
  })

  return (
    <Layout>
      <ProfessionalsContent />
    </Layout>
  )
}

export const Head = () => {
  const locale = getLocale()
  return (
    <IntlProvider messages={messagesForLanguage(locale)} locale={locale}>
      <Seo path={getPathName()} descriptionId="meta.description" />
    </IntlProvider>
  )
}

export default ProfessionalsPage

const features: ITrackedFeaturedItem[] = [
  {
    key: "solutions.professionals.showcase.social",
    icon: Icons.Persons,
    Screenshot: Screenshots.SocialNetwork,
  },
  {
    key: "solutions.professionals.showcase.patient",
    icon: Icons.FileCross,
    Screenshot: Screenshots.PatientFile,
  },
  {
    key: "solutions.professionals.showcase.voicemails",
    icon: Icons.Mic,
    Screenshot: Screenshots.VoiceMessages,
  },
  {
    key: "solutions.professionals.showcase.multimedia",
    icon: Icons.Cloud,
    Screenshot: Screenshots.Media,
  },
  {
    key: "solutions.professionals.showcase.pdf",
    icon: Icons.Document,
    Screenshot: Screenshots.PDFExport,
  },
  {
    key: "solutions.professionals.showcase.notifications",
    icon: Icons.Bell,
    Screenshot: Screenshots.Notifications,
  },
  {
    key: "solutions.professionals.showcase.security",
    icon: Icons.LockUnlocked,
    Screenshot: Screenshots.Security,
  },
]
